body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  overflow: hidden;
}
.hidden {
  display: none;
}
.hidden-svg {
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

/* При открытии Sidebar скрываем отображение dates tags **/
.ServiceEntityContainer {
  container-type: inline-size;
  container-name: serviceEntity;
}

@container serviceEntity (width < 800px) {
  .ServiceEntityContent {
    grid-template-areas: 'name  meta' !important;
    grid-template-columns: minmax(80px, 100%) 1fr !important;
  }

  .ServiceEntityContent .canBeHidden {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  body {
    overflow: auto;
  }
}

.custom-typeahead-client .custom-typeahead-button-client {
  border-color: #209bcf;
}
